import Rollbar from "rollbar";

export default new Rollbar({
  enabled: import.meta.env.VITE_DEPLOYMENT_TYPE === "production",
  accessToken: import.meta.env.VITE_ROLLBAR_POST_CLIENT_TOKEN,
  captureUncaught: true,
  captureUnhandledRejections: true,
  environment: import.meta.env.VITE_ROLLBAR_ENV,
  captureIp: false,
  payload: {
    client: {
      javascript: {
        source_map_enabled: true,
        code_version: import.meta.env.CODE_VERSION,
        guess_uncaught_frames: true,
      },
    },
  },
  ignoredMessages: ["Script error"]
});
