import { createRouter, createWebHistory } from 'vue-router'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: "/guestbook/:hotel/:guestid?/:type?",
      name: "guestbook main old",
      component: () => import("@/views/GuestbookMain.vue"),
    },
    {
      path: "/preview/:hotel/:guestPhase?",
      name: "guestbook preview",
      component: () => import("@/views/GuestbookPreview.vue"),
    },
    {
      path: "/mobile/:hotel/:guestPhase?",
      name: "guestbook mobile",
      component: () => import("@/views/GuestbookMain.vue"),
    },
    {
      path: "/pages/:hotel/:pageId",
      name: "guestbook page",
      component: () => import("@/views/GuestbookPages.vue"),
    },
    {
      path: "/not-found",
      name: "not-found",
      component: () => import("@/views/NotFound.vue"),
    },
    {
      path: "/:hotel/:guestid?/:type?",
      name: "guestbook main",
      component: () => import("@/views/GuestbookMain.vue"),
    }
  ],
  scrollBehavior(to, _, savedPosition) {
    if (to.name === "guestbook page") {
      return { left: 0, top: 0 };
    } else if (to.hash) {
      return {
        el: to.hash,
        behavior: 'smooth'
      }
    } else if (savedPosition) {
      return savedPosition;
    }
  },
});


router.onError((error, to) => {
  if (
    error.message.includes('Failed to fetch dynamically imported module') ||
    error.message.includes('Importing a module script failed')
  ) {
    if (!to?.fullPath) {
      window.location.reload();
    } else {
      window.location = to.fullPath;
    }
  }

});

export default router;