<template>
  <div
    :class="{
      loadingBlockElement: displayType == 'displayBlock',
      loadingFixedElement: displayType == 'displayFixed',
    }"
  >
    <div class="loader"></div>
  </div>
</template>

<script>
export default {
  props: {
    displayType: String,
  },
};
</script>

<style scoped>
.loadingBlockElement {
  width: 100%;
  height: calc(100vh - 350px);
  display: flex;
  justify-content: center;
  align-items: center;
}
.loadingFixedElement {
  background-color: rgba(0, 0, 0, 0.8);
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.loader {
  animation: spinnerAnimation 1s linear infinite;
  border: 3px solid #ddd;
  border-top: 3px solid #48b9ba;
  border-radius: 50%;
  height: 75px;
  width: 75px;
}
@keyframes spinnerAnimation {
  to {
    border-top-color: #894e99;
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
</style>
