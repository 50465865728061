import { createApp } from "vue";
import { createPinia, PiniaVuePlugin } from 'pinia'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import 'normalize.css';
import "./assets/style.css";
import "./vue-awesome-icons";

import App from "./App.vue";
import router from "./router/index";
import rollbar from "./rollbar";

const app = createApp(App);
const pinia = createPinia();

app.config.globalProperties.$hardcodedPath = import.meta.env.VITE_PATH;
app.config.globalProperties.$rollbar = rollbar;

app.config.errorHandler = (err, vm) => {
  vm.$rollbar.error(err);
  throw err; // rethrow
};

app.component("VIcon", FontAwesomeIcon);
app.use(PiniaVuePlugin);
app.use(pinia);
app.use(router)

app.mount("#app");