<template>
  <div id="app" :style="{ backgroundColor: appBackgroundColor }">
    <fetching-loader
      v-if="initialLoader && $route.path != previewPath"
      display-type="displayFixed"
    ></fetching-loader>
    <guestbook-navigation
      v-if="$route.path != previewPath"
      @appBackgroundColor="setBgColor"
    ></guestbook-navigation>
    <router-view :initial-loader="initialLoader" />
  </div>
</template>
<script>
import { defineAsyncComponent } from "vue";

import EventBus from "./EventBus.js";
import FetchingLoader from "./components/globalComponents/FetchingLoader.vue";
const GuestbookNavigation = defineAsyncComponent(
  () => import("./components/GuestbookNavigation.vue"),
);

export default {
  name: "EasyGuestbookApp",
  components: {
    GuestbookNavigation,
    FetchingLoader,
  },
  data() {
    return {
      hideOverflow: false,
      initialLoader: true,
      appBackgroundColor: "#ffffff",
    };
  },
  computed: {
    previewPath() {
      let path =
        "/preview/" +
        this.$route.params.hotel +
        "/" +
        this.$route.params.guestPhase;

      return path;
    },
  },
  watch: {
    hideOverflow: function () {
      document.body.style.overflow = this.hideOverflow ? "hidden" : ""; //auto instead of '' maybe
      document.documentElement.style.overflow = this.hideOverflow
        ? "hidden"
        : "";
    },
    $route() {
      if (this.$route.name === "not-found") {
        this.initialLoader = false;
      }
    },
  },
  created() {
    EventBus.$on("bodyOverflow", (state) => (this.hideOverflow = state));
  },
  methods: {
    setBgColor(bgColor) {
      this.appBackgroundColor = bgColor;
      this.initialLoader = false;
    },
  },
};
</script>

<style>
html,
body,
#app {
  min-height: 100vh;
}
html {
  scroll-behavior: smooth;
}
#app {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

body {
  overflow-x: hidden;
  -ms-overflow-style: none;
}

body::-webkit-scrollbar {
  display: none;
}

* {
  box-sizing: border-box;
}

/* handle editor images */
.responsive {
  max-width: 100%;
  height: auto;
  object-fit: cover;
}
.headerText h1,
.headerText > div > p {
  margin: 0 !important;
}
h4 {
  font-weight: 200;
}
.videoWrapper iframe {
  height: 400px;
}

@media (max-width: 426px) {
  .videoWrapper iframe {
    height: auto;
  }
}

/*
    Custom classes for quill editor
  */

.ql-align-center {
  text-align: center;
}

.ql-align-left {
  text-align: left;
}

.ql-align-right {
  text-align: right;
}

.fullContent p {
  margin: 0 !important;
}

.ql-font-poppins {
  font-family: "Poppins" !important;
}

.ql-font-brandon {
  font-family: "Brandon Text Black" !important;
}

.ql-font-markot {
  font-family: "MarkOT" !important;
}

.ql-font-markotmedium,
.ql-font-markot-medium {
  font-family: "MarkOT Medium" !important;
}

.ql-font-markotbold,
.ql-font-markot-bold {
  font-family: "MarkOT Bold" !important;
}

.ql-font-dalafloda {
  font-family: "Dala Floda" !important;
}

.ql-font-helveticaneue,
.ql-font-helvetica-neue {
  font-family: "Helvetica Neue" !important;
}

.ql-font-gothamblack,
.ql-font-gotham-black {
  font-family: "Gotham Black" !important;
}

.ql-font-gothambook,
.ql-font-gotham-book {
  font-family: "Gotham Book" !important;
}

.ql-font-llbrownbold,
.ql-font-ll-brown-bold {
  font-family: "LL Brown Bold" !important;
}

.ql-font-llbrownlight,
.ql-font-ll-brown-light {
  font-family: "LL Brown Light" !important;
}

.ql-font-prumolight,
.ql-font-prumo-light {
  font-family: "Prumo Light" !important;
}

.ql-font-itcgaramond,
.ql-font-itc-garamond {
  font-family: "ITC Garamond" !important;
}

.ql-font-neuehass,
.ql-font-neue-hass {
  font-family: "Neue Hass" !important;
}

.ql-font-arial {
  font-family: "Arial" !important;
}

.ql-font-barlow {
  font-family: "Barlow" !important;
}
</style>
